<template>
  <div
    class="pt-6 pb-2 px-2 space-y-3 flex flex-col h-[calc(100%-53px)] overflow-y-auto rounded-b-md"
  >
    <loader :is-loading="loading" />
    <div class="email-header-row">
      <div class="left">{{ $t("COMMON.TEMPLATE") }}:</div>
      <div class="right">
        <tooltip
          ref="templatesTooltip"
          placement="bottom-start"
          trigger="click"
          hide-on-click="toggle"
          :on-click-outside="hideDropdown"
          :append-to="body"
          :arrow="false"
          sticky="reference"
          theme="none"
          interactive
          :popper-options="popperOptions"
        >
          <template #default>
            <lf-pill
              size="sm"
              class="uppercase font-bold ml-2 cursor-pointer h-[26px]"
              color-index="15"
            >
              <div class="space-x-2 flex items-center">
                <div data-cy="template-name" class="w-30 truncate text-left">
                  <span>{{ selectedTemplate?.name || "" }}</span>
                </div>
                <icon-base
                  :icon="IconArrowUp"
                  height="10"
                  width="10"
                  :class="{
                    'rotate-180 -translate-y-1':
                      !templatesTooltip?.state.isVisible
                  }"
                />
              </div>
            </lf-pill>
          </template>
          <template #content>
            <div class="p-2 w-100 bg-white shadow-lg rounded-md">
              <div class="sticky pb-2">
                <search-input
                  v-model="search"
                  data-cy="email-template-search"
                  class="w-full h-10"
                  no-padding
                  no-margin
                />
              </div>
              <ul
                data-cy="email-templates"
                class="px-1 text-sm text-headline overflow-y-auto overflow-x-hidden max-h-100 h-auto"
              >
                <li
                  v-for="(template, index) in filteredTemplatesOptions"
                  :key="template.id"
                  ref="templateListRef"
                  v-tooltip="{
                    content: template.name,
                    onShow: () => isTemplateOptionTruncated[index]
                  }"
                  class="py-2 px-3 rounded hover:bg-gray-200 whitespace-nowrap cursor-pointer text-xs font-semibold truncate"
                  @click="selectTemplate(template)"
                >
                  {{ template.name }}
                </li>
              </ul>
            </div>
          </template>
        </tooltip>
      </div>
    </div>
    <div class="email-header-row">
      <div class="left">{{ $t("COMMON.FROM") }}:</div>
      <div class="right">
        <div class="w-full">
          <lf-dropdown
            :value="sender"
            :options="senders"
            data-cy="email-template-from"
            full-width
            @change="handleUpdateSender"
          />
          <field-error-message
            v-if="erroredFields.includes('from')"
            :value="$t('COMMON.VALUE_IS_REQUIRED')"
          />
        </div>
      </div>
    </div>
    <div class="email-header-row to">
      <div class="left">{{ $t("COMMON.TO") }}:</div>
      <div class="right">
        <div class="w-full">
          <multiselect-dropdown
            class="w-full multiselect-dropdown"
            data-cy="email-template-to"
            name="to"
            placeholder=""
            :value="selectedRecipients"
            :options="recipientOptions"
            is-absolute-positioned
            scrollable-parent="do-service"
            :allow-trim="false"
            @change="handleUpdateRecipients"
          />
          <field-error-message
            v-if="erroredFields.includes('to')"
            :value="$t('COMMON.VALUE_IS_REQUIRED')"
          />
        </div>
      </div>
    </div>
    <div class="email-header-row">
      <div class="left">{{ $t("ORGANIZATION.EMAIL_TEMPLATES.CC") }}:</div>
      <div class="right relative">
        <icon-base
          v-if="ccInputWarning.show"
          v-tooltip="ccInputWarning.text"
          :icon="IconWarning"
          data-cy="cc-warning"
          class="field-warning"
        />
        <lf-email-input-multiple
          :value="ccInput"
          :emails="ccEmails"
          @change="handleCcChange"
        />
      </div>
    </div>
    <div class="email-header-row">
      <div class="left">{{ $t("EMAIL_INTEGRATION.BCC") }}:</div>
      <div class="right relative">
        <icon-base
          v-if="bccInputWarning.show"
          v-tooltip="bccInputWarning.text"
          :icon="IconWarning"
          data-cy="bcc-warning"
          class="field-warning"
        />
        <lf-email-input-multiple
          :value="bccInput"
          :emails="bccEmails"
          @change="handleBccChange"
        />
      </div>
    </div>
    <div class="email-header-row">
      <div class="left">{{ $t("ORGANIZATION.EMAIL_TEMPLATES.SUBJECT") }}:</div>
      <div class="right">
        <div class="w-full">
          <email-dynamic-field
            :model-value="selectedTemplate?.subject || ''"
            name="subject"
            data-cy="email-template-subject"
            class="email-headless w-full"
            :placeholder="$t('ORGANIZATION.EMAIL_TEMPLATES.SUBJECT')"
            @update:model-value="selectedTemplate.subject = $event"
          />
          <field-error-message
            v-if="erroredFields.includes('subject')"
            :value="$t('COMMON.VALUE_IS_REQUIRED')"
          />
        </div>
      </div>
    </div>
    <div class="grow relative">
      <email-dynamic-field
        :model-value="selectedTemplate?.body || ''"
        data-cy="email-template-body"
        class="dynamic-field__body"
        name="body"
        show-toolbar
        @update:model-value="selectedTemplate.body = $event"
      />
      <div
        ref="sendButtonBlockRef"
        class="bg-disabled p-2 absolute bottom-0 rounded-bl rounded-br left-0 right-0 border-l border-r border-b"
      >
        <field-error-message
          v-if="erroredFields.includes('body')"
          class="-ml-2"
          :value="$t('COMMON.VALUE_IS_REQUIRED')"
        />
        <div class="flex items-center justify-between">
          <div>
            {{ $t("COMMON.TYPE") }}
            <span class="bg-white px-2 py-1">[</span>
            {{
              $t(
                "ORGANIZATION.EMAIL_TEMPLATES.DYNAMIC_FIELD_SYMBOL_EXPLANATION_SHORT"
              ).toLowerCase()
            }}
          </div>
          <primary-button
            data-cy="send-button"
            class="send-button"
            :class="{ 'fixed bottom-[16px] right-[15px]': !targetIsVisible }"
            @click="sendEmail"
          >
            <icon-base :icon="IconSend" />
          </primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, toRaw } from "vue";
import type { EmailTemplate } from "@/models/clients";
import type { MultiSelectOption } from "@/models/options";
import type { EmailType } from "@/models/emails";
import type { TippyComponent } from "vue-tippy";
import emailService from "@/services/modules/emails";
import useEmailsStore from "@/stores/emails";
import { useElementStatus } from "@/hooks/elements";
import { usePromiseWrapper } from "@/hooks/common";
import { useI18n } from "vue-i18n";
import { useNotification } from "@/hooks/notifications";
import { storeToRefs } from "pinia";
import EmailDynamicField from "@/views/profile/EmailDynamicField.vue";
import MultiselectDropdown from "@/components/ui/inputs/MultiselectDropdown.vue";
import LfEmailInputMultiple from "@/components/ui/inputs/LfEmailInputMultiple.vue";
import IconSend from "@/components/icons/IconSend.vue";
import IconArrowUp from "@/components/icons/IconArrowUp.vue";
import FieldErrorMessage from "@/components/ui/inputs/FieldErrorMessage.vue";
import cloneDeep from "lodash/cloneDeep";
import IconWarning from "@/components/icons/IconWarning.vue";
import IconBase from "@/components/ui/IconBase.vue";
import { useElementVisibility } from "@vueuse/core";
import uniqBy from "lodash/uniqBy";

const emit = defineEmits(["close-dialog"]);

const store = useEmailsStore();
const { t } = useI18n();
const { showMessage } = useNotification();
const { isTextTruncated } = useElementStatus();

type SendEmailTemplate = Pick<EmailTemplate, "name" | "subject" | "body">;

const templateListRef = ref<(HTMLElement | null)[]>([]);
const sendButtonBlockRef = ref<HTMLElement | null>(null);
const search = ref("");
const templatesTooltip = ref<TippyComponent | null>(null);
const popperOptions = ref<TippyComponent["popperOptions"]>({
  modifiers: [
    {
      name: "flip",
      options: { fallbackPlacements: ["top-start"] }
    }
  ]
});
const body = ref(document.body);
const targetIsVisible = useElementVisibility(sendButtonBlockRef);

const { modalData } = storeToRefs(store);
modalData.value.templates.unshift({
  id: "0",
  name: t("COMMON.SELECT"),
  subject: "",
  body: ""
});
const selectedTemplate = ref<SendEmailTemplate>(
  cloneDeep(modalData.value?.templates?.[0]) || {
    name: "",
    subject: "",
    body: ""
  }
);
const senders = computed(() => modalData.value?.email_addresses || []);
const sender = ref<EmailType | null>(senders.value[senders.value.length - 1]);
const recipientOptions = computed<MultiSelectOption[]>(() =>
  uniqBy(
    modalData.value?.applications?.flatMap(
      ({ owners }) =>
        owners?.map((owner) => ({
          id: String(owner.personal_info_id),
          name: owner.email_address
        }))
    ),
    "id"
  )
);
const selectedRecipients = ref<Pick<MultiSelectOption, "id">[]>(
  recipientOptions.value.map(({ id }) => ({ id }))
);
const ccInput = ref<string | null>(null);
const bccInput = ref<string | null>(null);
const ccEmails = ref<string[]>([]);
const bccEmails = ref<string[]>([]);
const errorMessage = ref<string | null>(null);
const erroredFields = ref<string[]>([]);

const isTemplateOptionTruncated = isTextTruncated(templateListRef);

const ccInputWarning = computed(() => ({
  text: t("ORGANIZATION.EMAIL_TEMPLATES.EMAILS_INPUT_WARNING", {
    inputName: t("ORGANIZATION.EMAIL_TEMPLATES.CC").toUpperCase(),
    emails: filterEmailsInput(ccInput.value)
  }),
  show: !!ccInput.value?.trim().length
}));

const bccInputWarning = computed(() => ({
  text: t("ORGANIZATION.EMAIL_TEMPLATES.EMAILS_INPUT_WARNING", {
    inputName: t("EMAIL_INTEGRATION.BCC").toUpperCase(),
    emails: filterEmailsInput(bccInput.value)
  }),
  show: !!bccInput.value?.trim().length
}));

const filteredTemplatesOptions = computed(() => {
  return modalData.value?.templates.filter(
    (field) =>
      field.name?.toLowerCase().includes(search.value.toLowerCase()) &&
      field.name !== t("COMMON.SELECT")
  );
});

const selectTemplate = (template: SendEmailTemplate) => {
  selectedTemplate.value = structuredClone(toRaw(template));
  hideDropdown();
};

const filterEmailsInput = (inputValue: string | null) =>
  inputValue
    ?.split(",")
    .map((email) => email.trim())
    .filter(Boolean)
    .join(", ");

const handleUpdateSender = (index: number) => {
  sender.value = senders.value[index];
};

const handleUpdateRecipients = (value: Pick<MultiSelectOption, "id">[]) => {
  selectedRecipients.value = value;
};

const handleCcChange = ({
  validEmails,
  input
}: {
  validEmails: string[];
  input: string;
}) => {
  ccInput.value = input;
  ccEmails.value = validEmails;
};

const handleBccChange = ({
  validEmails,
  input
}: {
  validEmails: string[];
  input: string;
}) => {
  bccInput.value = input;
  bccEmails.value = validEmails;
};

const { loading, fetchWrapper: sendEmail } = usePromiseWrapper(
  async () => {
    errorMessage.value = null;
    erroredFields.value = [];
    if (
      !sender.value ||
      !selectedRecipients.value.length ||
      !selectedTemplate.value.subject ||
      !selectedTemplate.value.body
    ) {
      erroredFields.value = [
        !sender.value ? "from" : "",
        !selectedRecipients.value.length ? "to" : "",
        !selectedTemplate.value.subject ? "subject" : "",
        !selectedTemplate.value.body ? "body" : ""
      ].filter(Boolean) as string[];
      errorMessage.value = t("ORGANIZATION.EMAIL_TEMPLATES.MISSING_DATA");
      return;
    }
    const recipients = selectedRecipients.value.map((recipient) =>
      recipient.id ? Number(recipient.id) : 0
    );
    const applicationsPayload = modalData.value.applications.reduce<
      Record<string, string[]>
    >((prev, curr) => {
      const applicationRecipients = curr.owners
        .filter(({ personal_info_id }) => recipients.includes(personal_info_id))
        .map(({ email_address }) => email_address);
      prev[curr.id] = applicationRecipients;
      return prev;
    }, {});
    await emailService.sendApplicationEmail({
      applications: applicationsPayload,
      subject: selectedTemplate.value.subject,
      body: selectedTemplate.value.body,
      preferred_email_address: sender.value,
      cc: ccEmails.value.length ? ccEmails.value : undefined,
      bcc: bccEmails.value.length ? bccEmails.value : undefined
    });
    emit("close-dialog");
  },
  {
    finally: () => {
      if (errorMessage.value) {
        showMessage(errorMessage.value, "error");
        return;
      }
    }
  }
);

const hideDropdown = () => {
  templatesTooltip.value?.hide();
};
</script>

<style scoped>
.email-header-row {
  @apply flex w-full items-start space-x-2;

  .left {
    @apply w-1/5 pl-4 font-semibold text-label;
  }

  .right {
    @apply w-4/5 pr-4 relative flex justify-start;
  }
}

.field-warning {
  @apply text-orange-400 absolute top-0-5 -left-5;
}

:deep() {
  .dynamic-field__body {
    @apply h-full;
    > .flex {
      @apply h-full;
      .ql-container {
        max-height: none;
        .ql-editor {
          max-height: none;
          padding-bottom: 3rem;
        }
      }
    }
  }
  .multiselect-dropdown {
    @apply mb-0;
  }

  .send-button {
    @apply px-2 w-[34px];
  }
}
</style>
