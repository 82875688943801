import type { SendApplicationEmailModalData } from "@/models/emails";
import emailService from "@/services/modules/emails";
import { defineStore } from "pinia";

interface UseEmailsState {
  modalData: SendApplicationEmailModalData;
}

const getDefaultState = (): UseEmailsState => ({
  modalData: {
    applications: [],
    email_addresses: [],
    templates: []
  }
});

const useEmailsStore = defineStore("emails", {
  state: getDefaultState,
  actions: {
    async getApplicationEmailModalData(application_ids: string[]) {
      const data =
        await emailService.getApplicationEmailModalData(application_ids);
      this.modalData = data;
    }
  }
});

export default useEmailsStore;
